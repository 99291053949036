<template>
  <van-loading v-if="loading" />
  <div v-else>
    <van-nav-bar
      v-if="!loading && task && task.for_user"
      :title="$t('pageTitle.task_form')"
      :border="false"
    >
      <template #left>
        <div
          class="page-title-btn"
          @click="isModal ? $emit('closePopup') : $router.go(-1)"
        >
          <img src="@/assets/images/arrow_left.svg" alt="" srcset="" />
        </div>
      </template>
      <template #right>
        <div class="avatar-holder">
          <img :src="task.for_user.avatar" alt="user avatar" />
        </div>
      </template>
    </van-nav-bar>

    <van-nav-bar
      v-else-if="!loading && task && task.from_user"
      :title="$t('pageTitle.task_for')"
      :border="false"
    >
      <template #left>
        <div
          class="page-title-btn"
          @click="isModal ? $emit('closePopup') : $router.go(-1)"
        >
          <img src="@/assets/images/arrow_left.svg" alt="" srcset="" />
        </div>
      </template>
      <template #right>
        <div class="avatar-holder">
          <img :src="task.from_user.avatar" alt="user avatar" />
        </div>
      </template>
    </van-nav-bar>

    <van-nav-bar
      v-else-if="!loading"
      :title="$t('pageTitle.my_task')"
      :border="false"
    >
      <template #left>
        <div
          class="page-title-btn"
          @click="isModal ? $emit('closePopup') : $router.go(-1)"
        >
          <img src="@/assets/images/arrow_left.svg" alt="" srcset="" />
        </div>
      </template>
    </van-nav-bar>

    <div class="task">
      <p class="task__text">
        {{ task.text }}
      </p>
      <div v-if="task.attachments">
        <van-image
          width="96"
          height="96"
          fit="contain"
          v-for="(item, key) in task.attachments"
          :key="key"
          :src="item"
          @click="show = true"
        />
      </div>
      <TaskControll
        @delegete="isDelegate = true"
        @category="isCategory = true"
        :task="task"
        @updateTask="updateTask"
        v-if="task.done == 0"
      />

      <Delegate
        :show-choose-button="false"
        :isDelegate="isDelegate"
        :task_id="task.id"
        @close="isDelegate = false"
      />

      <van-dialog
        v-model="isCategory"
        closeOnClickOverlay
        :showConfirmButton="false"
      >
        <ChooseCategory
          v-if="isCategory"
          :isCategory="isCategory"
          :task_id="task.id"
          @close="isCategory = false"
        />
      </van-dialog>

      <van-image-preview
        v-model="show"
        :images="task.attachments"
        :closeable="true"
      />
    </div>
  </div>
</template>

<script>
import TaskControll from "@/components/Task/Controll";
import ChooseCategory from "@/components/Task/ChooseCategory";
import Delegate from "@/components/Task/Delegate";
import { mapGetters, mapActions } from "vuex";
import ApiTasks from "@/api/tasks";

export default {
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDelegate: false,
      isCategory: false,
      show: false,
      index: 0,
      loading: true,
    };
  },
  computed: {
    ...mapGetters("Task", ["task"]),
  },
  methods: {
    ...mapActions("Task", ["setTask"]),
    onChange(index) {
      this.index = index;
    },
    updateTask(data) {
      this.setTask(data);
    },
  },
  components: {
    TaskControll,
    ChooseCategory,
    Delegate,
  },
  mounted() {
    if (!this.task || !this.task.length) {
      ApiTasks.getTask(this.$route.params.id || this.task.id).then(
        (responce) => {
          this.loading = false;
          this.setTask(responce.data);
        }
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.task {
  padding: 16px;
  background: #f9f8f9;
  border-radius: 12px;
  &__text {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 12px;
  }
}

.van-loading {
  display: flex;
  justify-content: center;
}
</style>
